<template>
  <div>
    <message :data="infoMessage" />
    <div
      ref="card"
      class="parcel-table">
      <v-data-table
        class="elevation-1 table-chat"
        :headers="headers"
        :items="parcels"
        hide-default-footer>
        <template v-slot:item="{ item }">
          <tr>
            <td class="text-left">
              <strong class="body-2">{{ item.parcel }}x {{ $n(item.parcelValue, 'currency') }}</strong>
            </td>
            <td class="text-center">
              {{ $n(item.originalValue, 'currency') }}
            </td>
            <td
              v-for="(detail, index) in item.details"
              :key="index"
              class="text-center">
              {{ $n(detail.value, 'currency') }}
            </td>
            <td class="text-center">
              <strong class="body-2">{{ $n(item.currentValue, 'currency') }}</strong><br>
            </td>
          </tr>
          <tr v-if="parcels.indexOf(item) === parcels.length - 1 && !showAllParcels">
            <td
              colspan="7"
              class="text-md-right">
              <a
                href=""
                class="accent--text"
                @click.prevent="actionAllParcels">Ver todas as parcelas</a>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
  import Message from './Message'
  import chatUiMixin from '@/mixins/chat-ui'
  import proposal from '../mocks/proposal'

  export default {
    components: { Message },
    mixins: [ chatUiMixin ],
    data () {
      return {
        infoMessage: {
          id: 91,
          isBot: true,
          message: '',
          input: false,
          options: null
        },
        headers: [
          { text: 'Parcelas', value: 'parcel', align: 'left' },
          { text: 'Valor Original', value: 'original', align: 'center' },
          { text: 'Correção monetária', value: 'correction_index', align: 'center' },
          { text: 'Multa', value: 'mulfineta', align: 'center' },
          { text: 'Juros', value: 'interest_rate', align: 'center' },
          { text: 'Honorários', value: 'fee', align: 'center' },
          { text: 'Total a pagar', value: 'currentValue', align: 'center' }
        ],
        installments: proposal.installments,
        parcels: [],
        showAllParcels: false
      }
    },
    computed: {
      isMobile () {
        return this.$vuetify.breakpoint.xsOnly
      }
    },
    mounted () {
      this.hasManyTickets()
      this.messageInfo()
      this.scaleAnimation()
    },
    methods: {
      messageInfo () {
        let message = this.isMobile ? 'Role a tela na horizontal para visualizar todos os dados' : 'Confira abaixo os detalhes financeiros das parcelas'
        this.infoMessage.message = message
      },
      hasManyTickets () {
        if (proposal.installments.length > 3) {
          this.parcels = proposal.installments.slice(0, 3)
          this.showAllParcels = false
        } else {
          this.showResumeTickets = true
        }
      },
      actionAllParcels () {
        this.parcels = proposal.installments
        this.showAllParcels = true
      },
      scaleAnimation () {
        const card = this.$refs.card

        this.anime({
          targets: card,
          easing: 'easeInOutQuad',
          delay: 800,
          scale: [
            { value: 0, duration: 100 },
            { value: 1, duration: 500 }
          ],
          opacity: [
            { value: 0, duration: 100 },
            { value: 1, duration: 200 }
          ]
        })
      }
    }
  }
</script>

<style lang="sass">
  .parcel-table
    opacity: 0
    transform: scale(0)
    margin-bottom: 30px

</style>
